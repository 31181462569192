import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { AGENT, INBOX_PAGE_CURRENT_TAB, propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.signupCustomerLink}>
        <FormattedMessage id="TopbarDesktop.signup1" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.loginCustomerLink}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: INBOX_PAGE_CURRENT_TAB }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, userType, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const menuItems = [
    {
      name: "ProfileSettingsPage",
      classNames: classNames(css.menuLink, currentPageClass('ProfileSettingsPage')),
      message: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
    }
  ];

  // if (userType == AGENT) {
  //   menuItems.unshift({
  //     name: "StripePayoutPage",
  //     classNames: classNames(css.menuLink, currentPageClass('StripePayoutPage')),
  //     message: <FormattedMessage id="TopbarDesktop.yourPayourLink" />
  //   });
  // }
  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfilePage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfilePage'))}
            name="ProfilePage"
            params={{ id: currentUser?.id?.uuid || "1111" }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.dashBoard" />
          </NamedLink>
        </MenuItem>
        {menuItems.map((st) => <MenuItem key={st.name}>
          <NamedLink
            className={st.classNames}
            name={st.name}
          >
            <span className={css.menuItemBorder} />
            {st.message}
          </NamedLink>
        </MenuItem>)}

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const userType = currentUser?.attributes?.profile?.publicData?.userType || {};
  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const inboxLinkMaybe = authenticatedOnClientSide && userType == AGENT ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} userType={userType} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;


  const textRefs = useRef([]);
  const imageRefs = useRef([]); // Separate ref for images
  const [hasLoaded, setHasLoaded] = useState(false);

  const isInView = (el, threshold = 0.25) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const elementHeight = rect.bottom - rect.top;

    // Check if the element is at least "threshold" portion in view
    const inViewHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
    const visibilityRatio = inViewHeight / elementHeight;

    return visibilityRatio > threshold;
  };

  const handleScroll = () => {
    if (!hasLoaded) return;

    textRefs.current.forEach((el) => {
      if (el && isInView(el)) {
        if (!el.classList.contains(css.inView)) {
          el.classList.add(css.inView);
        }
      }
    });

    imageRefs.current.forEach((el, index) => {
      if (el && isInView(el)) {
        // const animationClass = blockId === "why_choose_ankyr-block-1" ? css.inViewLeft : css.inViewRight; 
        if (!el.classList.contains(css.inViewPosition)) {
          el.classList.add(css.inViewPosition);
        }
      }
    });
  };

  useEffect(() => {
    setHasLoaded(true);
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger once on load

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasLoaded]);
  return (
    <nav className={classes}>
      <div className={css.contentWidth}>
        <div className={css.leftNav}>
          <LinkedLogo
            className={css.logoLink}
            layout="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            linkToExternalSite={config?.topbar?.logoLink}
          />
        </div>
        <div className={css.rightNav}>
          {/* <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
      /> */}

          {/* <CustomLinksMenu
            currentPage={currentPage}
            customLinks={customLinks}
            intl={intl}
            hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
          /> */}

          {/* {inboxLinkMaybe} */}
          {profileMenuMaybe}
          <div className={css.imageCard} ref={(el) => (imageRefs.current[0] = el)}>
            {signupLinkMaybe}
          </div>

          {/* {loginLinkMaybe} */}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
